import './App.css';
function App() {
    return (
        <div>
            <h1>Hello React!</h1>
        </div>
    );
}

export default App;
